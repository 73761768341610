import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { LightPro } from '@/images/support/index'

import { DetailHeader, DetailStart, Developer, DetailFaq } from '@/components/Support/index'

const Index = () => {
  return (
    <Layout menuBlack={true}>
      <SEO page="Support" />
      <DetailHeader pro='light' />
      <DetailStart updateLink='/support/update/#light' manualsLink='/support/Nreal_Light_After_Sales_&_Warranty_US_pdf' type='light' />

      <Developer />
      <DetailFaq type='light' />

    </Layout>
  )
}

export default Index